import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { push, replace } from 'react-router-redux';
// import { Link, Redirect } from 'react-router-dom';
// Material UI
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import Grow from '@material-ui/core/Grow'

import HelloLogo from '../assets/hello.png';

import { Creators as Actions } from '../actions';
// Components

const styles = (theme) => ({
    root: {
        flexGrow: 1,
        padding: 20,
        width: '100%',
        minHeight: 'calc(100% - 156px)',
        [theme.breakpoints.down('md')]: {
            marginTop: 20,
        },
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: 480,
        position: 'relative',
    },
    contentHeader: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%',
        padding: '30px 0',
        borderBottom: '3px solid #cfe1e3',
        textAlign: 'center',
    },
    miniContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: 565,
        position: 'relative',
    },
    title: {
        fontSize: 40,
        fontWeight: 700,
        color: '#00090F',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
        },
        fontFamily: 'Raleway Thin',
    },
    link: {
        lineHeight: 2,
        fontSize: 12,
        color: '#444444',
        marginTop: 24,
        marginBottom: 0,
        fontFamily: 'Raleway SemiBold',
        textDecoration: 'none',
    },
    linkLabel: {
        color: '#1DAFEC',
    },
    content: {
        lineHeight: 2,
        fontSize: 12,
        color: '#000409',
        fontFamily: 'Raleway Thin',
    },
    contents: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
    },
    containerWrapper: {
        width: '100%',
        background: '#cfe1e3',
        display: 'flex',
        justifyContent: 'center',
    },
    contentsTitle: {
        fontSize: 40,
        fontWeight: 700,
        color: '#00070c',
        textAlign: 'center',
        padding: '60px 0',
        [theme.breakpoints.down('md')]: {
            fontSize: 30,
            padding: '40px 0',
        },
        fontFamily: 'Raleway Thin',
    },
    grid: {
        margin: '100px 0',
        [theme.breakpoints.down('md')]: {
            margin: '50px 0',
        },
    },
    gridOne: {},
    grid1: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            textAlign: 'center',
            border: 'none',
        },
    },
    grid2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
        },
    },
    item: {
        cursor: 'pointer',
        padding: 10,
        textAlign: 'center',
        '&:hover $h1, &:hover $h2': {
            textShadow: '1px 0 0 #2f2f2f',
            '-webkit-text-shadow': '1px 0 0 #2f2f2f',
        },
    },
    itemActivity: {
        //  backgroundColor: '#ffffff',
        padding: 10,
        textAlign: 'left',
    },
    h1Activity: {
        color: '#000000',
        fontSize: 18,
        fontWeight: 'bold',
        lineHeight: 1.5,
        fontFamily: 'Raleway Regular',
    },
    h2Activity: {
        color: '#000000',
        fontSize: 14,
        fontWeight: 'bold',
        fontFamily: 'Raleway Regular',
    },

    h1: {
        fontSize: 15,
        fontWeight: 500,
        color: '#2f2f2f',
        lineHeight: 1.5,
        fontFamily: 'Raleway Regular',
    },
    h2: {
        fontSize: 12,
        fontWeight: 300,
        color: '#2f2f2f',
        fontFamily: 'Raleway Regular',
    },
    box: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '250px',
        border: '1px solid white',
        margin: '20px 0',
        cursor: 'pointer',
    },
    boxActivity: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '250px',
        border: '2px solid #ffffff',
        margin: '20px 0',
        cursor: 'pointer',
    },
    dollar: {
        fontSize: 18,
        padding: 0,
        margin: 0,
        fontFamily: 'Raleway Regular',
    },
    date: {
        fontSize: 14,
        paddingTop: 7,
        margin: 0,
    },
    number: {
        fontSize: 22,
        paddingTop: 0,
        margin: 0,
        fontFamily: 'Raleway Regular',
    },
    or: {
        width: 170,
        textAlign: 'center',
        padding: '15px 0',
        color: '#2f2f2f',
        fontFamily: 'Raleway SemiBold',
    },
    p1: {
        display: 'flex',
        fontSize: 18,
        textAlign: 'center',
        color: '#2f2f2f',
        padding: '5px 0',
        fontWeight: 300,
        fontFamily: 'Raleway Regular',
    },
    p1Activity: {
        display: 'flex',
        fontSize: 18,
        textAlign: 'center',
        color: '#000000',
        padding: '5px 0',
        fontFamily: 'Raleway SemiBold',
    },
    p2: {
        display: 'flex',
        fontSize: 11,
        textAlign: 'center',
        color: '#2f2f2f',
        fontWeight: 100,
        fontFamily: 'Raleway Regular',
    },
    p2Activity: {
        display: 'flex',
        fontSize: 12,
        textAlign: 'center',
        color: '#000000',
        fontFamily: 'Raleway SemiBold',
    },
    helloLogoDiv: {
        textAlign: 'center',
        marginTop: '24px',
    },
    helloLogoImg: {
        height: '46px',
    },
    gridContentWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    gridContentOne: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
    },
    gridContent1: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        borderRight: 'solid 1px #8f8f8f',
        [theme.breakpoints.down('md')]: {
            justifyContent: 'center',
            textAlign: 'center',
            border: 'none',
        },
    },
    gridContent2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        [theme.breakpoints.down('md')]: {
            alignItems: 'center',
        },
        justifyContent: 'space-around',
    },
    gridTitle: {
        fontSize: 40,
        fontFamily: 'Raleway Thin',
        margin: '40px 0 0 0',
        borderBottom: 'solid 1px #2f2f2f',
        width: '250px',
        textAlign: 'center',
    },
    availabilityLocationTitle: {
        fontSize: 40,
        fontFamily: 'Raleway',
        margin: '40px 0 0 0',
        borderBottom: 'solid 1px #2f2f2f',
        width: '250px',
        textAlign: 'center',
        color: 'white',
        fontWeight: 'bold',
    },
    availabilityLocation: {
        fontSize: 16,
        fontFamily: 'Raleway',
        textAlign: 'center',
        marginTop: '7.5px',
        marginBottom: 20,
        color: 'white',
        fontWeight: 'bold',
    },
});

class Intro extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: 'location',
            location: '',
        };
    }

    componentWillMount() {
        const { user } = this.props;

        this.props.getPlacesRequest();

        if (user) {
            this.props.getUserInfoRequest(user._id);
        }
    }

    componentWillReceiveProps(nextProps) {
        const { token, reschedule, userAppointment, user } = nextProps;

        if (token && !reschedule && userAppointment && user && user.isConfirm) {
            return this.props.push('/detail');
        }

        if (token && user && !user.isConfirm) {
            return this.props.push('/confirm-register');
        }
    }

    navigate = (to) => () => {
        this.props.push(to);
    };

    checkLocation(place, event) {
        this.setState({ location: place._id });
        if (this.state.mode !== '') {
            const data = {
                _place: place._id,
                addressOne: place.addressOne,
                addressTwo: place.addressTwo,
            };

            const localStorageData = {
                ...data,
            };

            if (this.props.user?._id) {
                localStorageData.userId = this.props.user._id;
            }

            localStorage.setItem('user_data', JSON.stringify(localStorageData));

            this.props.setSubscriptionType(data);
            this.props.push('/pickDate');
        }
    }

    render() {
        const { classes, place } = this.props;

        return (
            <div className={classes.root}>
                <div className={classes.contentHeader}>
                    <div className={classes.miniContainer}>
                        <div className={classes.title}>hello</div>
                        <div className={classes.helloLogoDiv}>
                            <img src={HelloLogo} className={classes.helloLogoImg} alt="hello-logo" />
                        </div>
                        <p className={classes.link}>WILL GRANT VISION X MUSICARES</p>
                        <div className={classes.content}>
                            Will Grant Vision is a for-purpose organization with a mission to give vision. We deliver
                            free eye exams and glasses to low-income children and underserved communities. In
                            partnership with Musicares, we are pleased to welcome you to our "See Thru Music"
                            initiative. In booking your eye exam with us, you are helping sustainably provide eyecare to
                            those who may otherwise go without it.
                        </div>
                    </div>
                </div>
                <div className={classes.contents}>
                    <div className={classes.contentsTitle}>where would you like your eye exam?</div>
                    <div className={classes.containerWrapper}>
                        <div className={classes.container}>
                            <Grid container className={classes.grid} justify="center" spacing={16}>
                                <Grid item xs={12} className={classes.gridOne}>
                                    <div className={classes.gridContentWrapper}>
                                        <div className={classes.availabilityLocationTitle}>location</div>
                                        <div className={classes.availabilityLocation}>
                                            availability varies by location
                                        </div>
                                        <div className={classes.gridContentOne}>
                                            {place.places
                                                .filter((p) => p.active)
                                                .map((place, index) => (
                                                    <div
                                                        key={place._id}
                                                        className={
                                                            this.state.location === place._id
                                                                ? classes.itemActivity
                                                                : classes.item
                                                        }
                                                        onClick={this.checkLocation.bind(this, place)}
                                                    >
                                                        <div
                                                            className={
                                                                this.state.location === place._id
                                                                    ? classes.h1Activity
                                                                    : classes.h1
                                                            }
                                                        >
                                                            {place.addressOne}
                                                        </div>
                                                        <div
                                                            className={
                                                                this.state.location === place._id
                                                                    ? classes.h2Activity
                                                                    : classes.h2
                                                            }
                                                        >
                                                            {place.addressTwo}
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Intro.propTypes = {
    classes: PropTypes.object.isRequired,
};

const { getUserInfoRequest, setSubscriptionType, getPlacesRequest } = Actions;

const mapStateToProps = ({ auth: { token, user, userAppointment }, user: { reschedule }, place }) => ({
    user,
    userAppointment,
    token,
    reschedule,
    place,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            push,
            replace,
            getUserInfoRequest,
            setSubscriptionType,
            getPlacesRequest,
        },
        dispatch
    );

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(Intro);
